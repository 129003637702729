import React from 'react'

const IconArrowLeft = ({
  width = 14,
  height = 12,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="m5.99 11.69 1.414-1.414L3.16 6.033 7.404 1.79 5.989.376.333 6.033l5.656 5.657Z"
        fill={fill}
      />
      <path d="M2.697 6.983v-2H14v2H2.697Z" fill={fill} />
    </svg>
  )
}

export default IconArrowLeft
