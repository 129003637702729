import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'

export const AccordionItemMain = styled.div`
  border-top: 0.1rem solid;
`

export const AccordionToggle = styled.button`
  position: relative;
  display: block;
  width: 100%;
  ${clamp('padding-top', 24, 40)}
  ${clamp('padding-right', 24, 50)}
  ${clamp('padding-bottom', 24, 40)}
  text-align: left;
  color: currentColor;
  border: none;
  background-color: transparent;
`

export const AccordionToggleIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  ${clamp('width', 14, 25)}
`

export const AccordionContent = styled.div`
  overflow: hidden;
  will-change: height;
`

export const AccordionContentInner = styled.div`
  ${clamp('padding-bottom', 24, 40)}
`
