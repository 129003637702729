import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading2, Heading3, TextBody } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Flow from '@components/Flow'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import Tabs from '@components/Tabs'
import { colors } from '@styles/vars/colors.style'
import VimeoPlayer from '@components/VimeoPlayer'
import Button from '@components/Button'
import IconArrowLeft from '@svgs/IconArrowLeft'
import IconArrowRight from '@svgs/IconArrowRight'
import RichText from '@components/RichText'
import Accordion from '@components/Accordion'
import Modal, { modalAnimationDurationS } from '@components/Modal'
import { clamp } from '@styles/utils/conversion.style'
import Link from '@components/Link'

const DummyModal = styled.div`
  width: 90%;
  max-width: 78rem;
  ${clamp('padding', 24, 48)};
  ${clamp('border-radius', 8, 12)};
  background-color: ${colors.light};
`

const StyleguidePage = ({ data }) => {
  const [showModal, setShowModal] = useState(false)
  const { seo, slug, content, link } = data.contentfulStyleguide
  const dummyTabData = [
    {
      title: 'Tab 1',
      content: <TextBody>Tab 1 content</TextBody>,
    },
    {
      title: 'Tab 2',
      content: <TextBody>Tab 2 content</TextBody>,
    },
    {
      title: 'Tab 3',
      content: <TextBody>Tab 3 content</TextBody>,
    },
  ]

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  const accordions = [
    {
      id: 'toggle-1',
      open: false,
      summary: '1. An accordion toggle',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 'toggle-2',
      open: true,
      summary: '2. An accordion toggle',
      details:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      id: 'toggle-3',
      open: false,
      summary: '3. An accordion toggle',
      details:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio quisquam voluptatem, dolorum deserunt dicta dolore delectus ad velit mollitia modi consequatur ea! Autem quod eveniet numquam fuga, earum minus deserunt. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit sint fugit ad soluta tempore! Neque ipsam earum beatae fuga est sunt accusamus autem maxime nostrum provident! Cumque itaque optio autem.',
    },
  ]

  return (
    <>
      <Seo data={seo} slug={slug} />

      <Container>
        <Spacer size={[120, 260]} />

        <Grid>
          <GridItem tabletL={6} tabletLStart={4}>
            {content && <RichText content={content} />}

            <Spacer size={[40, 80]} />

            <Heading2 color={colors.blue}>
              <AnimateSplitText>Components</AnimateSplitText>
            </Heading2>

            <Spacer size={[24, 48]} />

            <Heading3>
              <AnimateSplitText>Buttons</AnimateSplitText>
            </Heading3>

            <Spacer size={[12, 24]} />

            <Flow direction="horizontal">
              <AnimateSlideIn>
                <Button onClick={() => alert('A primary click')}>
                  Primary button
                </Button>
              </AnimateSlideIn>

              <AnimateSlideIn>
                <Button
                  variant="secondary"
                  onClick={() => alert('A secondary click')}
                >
                  Secondary button
                </Button>
              </AnimateSlideIn>
            </Flow>

            <Spacer size={[9, 18]} />

            <Flow direction="horizontal">
              <AnimateSlideIn>
                <Button disabled onClick={() => {}}>
                  Disabled button
                </Button>
              </AnimateSlideIn>

              <AnimateSlideIn>
                <Button
                  type="externalLink"
                  href="https://toyfight.co"
                  iconRight={<IconArrowRight />}
                  target="_blank"
                >
                  An external link
                </Button>
              </AnimateSlideIn>
            </Flow>

            <Spacer size={[9, 18]} />

            <Flow direction="horizontal">
              <AnimateSlideIn>
                <Button type="internalLink" to="/" iconLeft={<IconArrowLeft />}>
                  A page navigation button
                </Button>
              </AnimateSlideIn>

              <AnimateSlideIn>
                <Button label="Go back" onClick={() => {}}>
                  <IconArrowLeft responsive={false} />
                </Button>
              </AnimateSlideIn>
            </Flow>

            <Spacer size={[24, 48]} />

            <Heading3>
              <AnimateSplitText>Accordion</AnimateSplitText>
            </Heading3>

            <Spacer size={[12, 24]} />

            <Accordion items={accordions} showOneItem={false} />

            <Spacer size={[24, 48]} />

            <Heading3>
              <AnimateSplitText>Modal</AnimateSplitText>
            </Heading3>

            <Spacer size={[12, 24]} />

            <Button onClick={openModal}>Open the modal</Button>

            <Modal
              isOpen={showModal}
              onDismiss={closeModal}
              ariaLabel="An example modal"
            >
              <DummyModal>
                <Heading2 as="h2">
                  <AnimateSplitText
                    delay={modalAnimationDurationS}
                    triggerOnce={false}
                  >
                    This is a modal
                  </AnimateSplitText>
                </Heading2>

                <Spacer size={[12, 24]} />

                <TextBody>
                  <AnimateSplitText
                    delay={modalAnimationDurationS}
                    triggerOnce={false}
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Labore sint soluta facilis rem consequatur, assumenda
                    maiores unde molestiae, modi, animi quisquam illo at
                    necessitatibus reprehenderit ab hic quae dicta laboriosam.
                  </AnimateSplitText>
                </TextBody>

                <Spacer size={[12, 24]} />

                <AnimateFadeIn
                  delay={modalAnimationDurationS}
                  triggerOnce={false}
                >
                  <Button onClick={closeModal}>Close</Button>
                </AnimateFadeIn>
              </DummyModal>
            </Modal>

            <Spacer size={[24, 48]} />

            <Heading3>
              <AnimateSplitText>Vimeo Player</AnimateSplitText>
            </Heading3>

            <Spacer size={[12, 24]} />

            <AnimateSlideIn>
              <VimeoPlayer id="148751763" />
            </AnimateSlideIn>

            <Spacer size={[24, 48]} />

            <Heading3>
              <AnimateSplitText>Tabs</AnimateSplitText>
            </Heading3>

            <Spacer size={[12, 24]} />

            <AnimateSlideIn>
              <Tabs data={dummyTabData} />
            </AnimateSlideIn>

            <Spacer size={[12, 24]} />

            <Link link={link} />
          </GridItem>
        </Grid>

        <Spacer size={[120, 360]} />
      </Container>
    </>
  )
}

export default StyleguidePage

export const pageQuery = graphql`
  query StyleguidePage($slug: String!) {
    contentfulStyleguide(slug: { eq: $slug }) {
      seo {
        ...SEO
      }
      slug
      link {
        ... on Node {
          ...Link
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData
            description
            file {
              url
            }
          }
          ... on ContentfulStyleguide {
            contentful_id
            slug
          }
        }
      }
    }
  }
`
