import React from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'

const Link = ({ link, ...props }) => (
  <>
    {link.page && (
      <Button
        type="internalLink"
        to={link.page.slug ? link.page.slug : '/'}
        {...props}
      >
        {link.text}
      </Button>
    )}

    {link.url && (
      <Button type="externalLink" href={link.url} {...props}>
        {link.text}
      </Button>
    )}
  </>
)

Link.propTypes = {
  link: PropTypes.object,
}

export default Link
